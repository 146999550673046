import { getUserToken, getInfo} from '@/api/index'
import { getUrlToken } from '@/utils/index.js'
import { Toast } from 'vant'
const user = {
  state: {
    // token: getUrlToken('token'),
    userInfo:{},
    tenantInfo:{},
    userId:'',
    userNickName:''
  },

  mutations: {
    SET_USERINFO: (state, userInfo) =>{
      state.userInfo = userInfo
    },
    SET_TENANTINFO: (state, tenantInfo) =>{
      state.tenantInfo = tenantInfo
    },
    SET_USERID: (state,usreId) =>{
      state.usreId = usreId
    },
    SET_USER_NICKNAME: (state,userNickName) =>{
      state.userNickName = userNickName
    }
  },

  actions: {
    Login({ dispatch,state }){
      const adminToken = sessionStorage.getItem("userToken");
			if (adminToken) {
				return Promise.resolve(true);
			}
      return new Promise((resolve, reject) => {
        getUserToken(state.token).then(res => {
          // 拿到使用应用token,缓存到sessionStorages
          if(res.code==200){
            sessionStorage.setItem('userToken',res.data.access_token);
            dispatch('GetInfo');
            resolve()
          }else{
            Toast(res.msg);
          }
        }).catch(error => {
          
          reject(error)
        })
      })
    },
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          if(res.tenantData && res.tenantData.length>0){
            const tenantInfo = res.tenantData[0];
            commit('SET_TENANTINFO',tenantInfo)
          }
          commit('SET_USERINFO',res.user)
          commit('SET_USERID',res.user.userId)
          commit('SET_USER_NICKNAME',res.user.nickName)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default user
