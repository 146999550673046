import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'font-awesome/css/font-awesome.min.css'
import '@/assets/styles/index.scss'
import VoPages from 'vo-pages'
import 'vo-pages/lib/vo-pages.css'

import { Toast } from 'vant'
Vue.component('VoPages',VoPages);
Vue.use(Vant);
// h5设置子页面标题指令插件
Vue.use(require('vue-wechat-title'));
Vue.config.productionTip = false
Vue.prototype.$toast = Toast

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
