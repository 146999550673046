import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getters'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    keepAliveRoutes: []
  },
  modules: {
    user
  },
  getters,
  mutations: {
    ADD_CACHED_VIEW: (state, view) => {
      if (state.keepAliveRoutes.includes(view)) return
        state.keepAliveRoutes.push(view)
    },
    DEL_CACHED_VIEW: (state, view) => {
      const index = state.keepAliveRoutes.indexOf(view)
      index > -1 && state.keepAliveRoutes.splice(index, 1)
    },
    DEL_ALL_CACHED_VIEWS: state => {
      state.keepAliveRoutes = []
    }
  },
  actions: {
    addCachedView({ commit }, view) {
      commit('ADD_CACHED_VIEW', view)
    },
    delCachedView({ commit, state }, view) {
      return new Promise(resolve => {
        commit('DEL_CACHED_VIEW', view)
        resolve([...state.keepAliveRoutes])
      })
    },
    delAllCachedViews({ commit, state }) {
      return new Promise(resolve => {
        commit('DEL_ALL_CACHED_VIEWS')
        resolve([...state.keepAliveRoutes])
      })
    },
  }
})
