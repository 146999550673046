import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
}
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    name: 'Index',
    path: '/index',
    component: (resolve) => require(['@/views/Index'], resolve),
    meta: {
      title: '投控OA',
      noCache:false
    },
  },
  {
    name: 'todolist',
    path: '/todolist',
    component: (resolve) => require(['@/views/office/todolist'], resolve),
    meta: {
      title: '待办列表',
      noCache:false
    },
    beforeEnter: (to, from, next) => {
      if (to.query.title) {
        to.meta.title = to.query.title
      }
      next()
    }
  },
  {
    name: 'textfile',
    path: '/textfile',
    component: (resolve) => require(['@/views/office/textFile'], resolve),
    meta: {
      title: '文件',
      noCache:false
    },
    beforeEnter: (to, from, next) => {
      if (to.query.title) {
        to.meta.title = to.query.title
      }
      next()
    }
  },
  {
    name: 'donelist',
    path: '/donelist',
    component: (resolve) => require(['@/views/office/donelist'], resolve),
    meta: {
      title: '已办列表',
      noCache:false
    },
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: (resolve) => require(['@/views/office/dashboard'], resolve),
    meta: {
      title: '办件统计',
      noCache:false
    },
  },
  {
    name: 'meeting',
    path: '/meeting',
    component: (resolve) => require(['@/views/schedule/meetingTable'], resolve),
    meta: {
      title: '会议室安排',
      noCache:false
    },
  },
  {
    name: 'leaderwork',
    path: '/leaderwork',
    component: (resolve) => require(['@/views/schedule/leaderWork'], resolve),
    meta: {
      title: '日程安排',
      noCache:false
    },
  },
  // {
  //   name: 'receivelist',
  //   path: '/receivelist',
  //   component: (resolve) => require(['@/views/work/receivelist'], resolve),
  //   meta: {
  //     title: '已收提醒列表',
  //     noCache:false
  //   },
  // },
  // {
  //   name: 'sendlist',
  //   path: '/sendlist',
  //   component: (resolve) => require(['@/views/work/sendlist'], resolve),
  //   meta: {
  //     title: '已发提醒列表',
  //     noCache:false
  //   },
  // },
  {
    name: 'noticelist',
    path: '/noticelist',
    component: (resolve) => require(['@/views/work/noticelist'], resolve),
    meta: {
      title: '已发提醒',
      noCache:false
    },
    beforeEnter: (to, from, next) => {
      if (to.query.title) {
        to.meta.title = to.query.title
      }
      next()
    }
  },
  {
    name: 'noticeDetail',
    path: '/notice/detail',
    component: (resolve) => require(['@/views/work/noticeDetail'], resolve),
    meta: {
      title: 'OA已发提醒',
      noCache:false
    },
    beforeEnter: (to, from, next) => {
      if (to.query.title) {
        to.meta.title = to.query.title
      }
      next()
    }
  },
  {
    name: 'concerns',
    path: '/concerns',
    component: (resolve) => require(['@/views/office/concerns'], resolve),
    meta: {
      title: '已关注列表',
      noCache:false
    },
  },
  {
    name: 'Main',
    path: '/main',
    component: (resolve) => require(['@/views/office/main'], resolve),
    meta: {
      title: '投控OA',
      noCache: true
    },
  },
  {
    name: 'accountBinding',
    path: '/account/binding',
    component: (resolve) => require(['@/views/work/accountBinding'], resolve)
  },
  {
    name: 'resetPwd',
    path: '/resetpwd',
    component: (resolve) => require(['@/views/work/resetPwd'], resolve)
  },
  {
    name: 'selectUser',
    path: '/select/user',
    component: (resolve) => require(['@/views/office/selectUser'], resolve),
    meta: {
      title: '人员选择',
      noCache:false
    },
  },

]

const router = new Router({
  mode: 'history',
  base: '/',
  // stk-oa-company-wx/
  routes
})

export default router
