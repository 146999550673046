import request from '../utils/request'
import requestFlow from '../utils/requestFlow'

// 查询待办列表
export function myFlowList(params) {
    return request({
        url: '/flow/getMyProcessList',
        method: 'get',
        params
    })
}

// 获取词条接口
export function wordList(params) {
    return requestFlow({
        url: '/swiftportal-modeler/words/list',
        method: 'get',
        params
    })
}

// 获取审批操作按钮 taskId
export function actionMenus(params) {
    return requestFlow({
        url: '/swiftportal-modeler/widget/render/getApproveArea/'+params.taskId,
        method: 'get',
        params
    })
}

// 获取正文
export function getTextFile(params) {
    return requestFlow({
        url: '/swiftportal-modeler/widget/render/getTextFile',
        method: 'get',
        params
    })
}

// 获取附件
export function getFileList(params) {
    return requestFlow({
        url: '/swiftportal-modeler/swiftfile/getFileList',
        method: 'get',
        params
    })
}

// 审批提交接口
export function approveSubmit(data) {
    return requestFlow({
        url: '/swiftportal-modeler/widget/render/button',
        method: 'post',
        data
    })
}
// 本部流程跟踪接口
export function getReminderMsgList(params) {
    return request({
        url: '/processQuery/getProcessTrack',
        method: 'get',
        params
    })
}
// 获取已收文件列表
export function getNoticeList(params) {
    return request({
        url: '/system/notice/listPage',
        method: 'get',
        params
    })
}

// 获取已关注文件列表
export function followList(data) {
    return request({
        url: '/follow/list',
        method: 'post',
        data
    })
}

export function getProcessInstData(params) {
    return request({
      url: '/flow/getProcessInstData',
      method: 'get',
      params
    })
  }
  
// 获取流程表单打印配置
export function getPrintConfig(params) {
    return request({
        url: '/flow/getPrintConfig',
        method: 'get',
        params
    })
}
// 获取在线预览文件
export function getFileViewUrl(params) {
    return request({
        url: '/fileViewEdit/getFileViewUrlByFlow',
        method: 'get',
        params
    })
}

// 获取会议室安排列表
export function getMyMeeting(params) {
    return request({
        url: '/system/meetingJoin/ownJoinMeetings',
        method: 'get',
        params
    })
}

// 获取领导一周工作安排表
export function getLeaderWorkList(params) {
    return request({
        url: '/office/acticityLeader/listActivitys',
        method: 'get',
        params
    })
}

// 获取领导一周工作安排表
export function getMyWorkList(params) {
    return request({
        url: '/office/acticityLeader/ownActivitys', 
        method: 'get',
        params
    })
}

// 获取会议室参会人员
export function getMeetingUser(applyId) {
    return request({
        url: '/system/meetingJoin/ownJoinMeetings/'+applyId,
        method: 'get',
    })
}

// 通过公告id获取已阅用户信息
export function getReadUserList(params) {
    return request({
        url: '/system/notice/findReadUserByNoticeId',
        method: 'get',
        params
    })
}

// 通过公告详情接口
export function getNoticeDetail(noticeId) {
    return request({
        url: '/system/notice/getDetail/' + noticeId,
        method: 'get'
    })
}
// 获取用户token
export function getUserToken(params) {
    return request({
        url: '/system/notice/getDetail/',
        method: 'get',
        params
    })
}

// 获取当前登录用户信息
export function getInfo() {
    return request({
        url: '/getInfo',
        method: 'get'
    })
}

// 密码重置接口
export function resetPassword(data) {
    return request({
        url: '/system/user/resetPwd',
        method: 'post',
        data
    })
}

// 企业微信绑定OA账号接口
export function accountBind(data) {
    return request({
        url: '/system/user/accountbind',
        method: 'post',
        data
    })
}