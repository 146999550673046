<template>
  <div id="app">
    <keep-alive :include="keepAliveRoutes">
      <router-view :key="key" v-wechat-title="$route.meta.title"/>
    </keep-alive>
  </div>
</template>
<script>
export default {
  computed: {
    keepAliveRoutes() {
      return this.$store.state.keepAliveRoutes
    },
    key() {
      return this.$route.path
    }
  },
  created() {
  },
  mounted() {
    this.$store.dispatch('GetInfo');
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  // min-height: 100vh;
  height: 100%;
  width: 100%;
  -moz-osx-font-smoothing: grayscale;
  background: #F5F5F5;
}
html,body{
  height: 100%;
}
a {
  text-decoration: none;
  color: #333;
}

a,
//p,
span,
h1,
h2,
h3,
h4,
h5,
ul,
div {
  padding: 0;
  margin: 0;
  font-size: 0.14rem;
}
</style>
